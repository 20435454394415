import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["category"];

  select(event) {
    this.categoryTargets.forEach((category) => {
      category.classList.remove("active");
    });

    event.currentTarget.classList.add("active");
  }
}
