import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["form", "errorMessage"];

  static values = { url: String };

  async validateFormData() {
    let response = await this.performRequest()
    let message = await response.text()

    this.addErrorMessage(message)
  }

  async performRequest() {
    return fetch(this.urlValue, this.requestOptions())
  }

  requestOptions() {
    return {
      method: "POST",
      headers: { "X-CSRF-Token": this.csrfToken() },
      body: new FormData(this.formTarget)
    };
  }

  addErrorMessage(message) {
    this.errorMessageTarget.innerHTML = message;
  }

  csrfToken() {
    return document.querySelector("meta[name='csrf-token']").content;
  }
}
