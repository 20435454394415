import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    stationPrimaryColor: String,
    stationSecondaryColor: String,
    svgPath: String
  }

  async connect() {
    const svg = await this.fetchAndModifySvg();
    this.setSvgAsBackground(svg);
  }

  async fetchAndModifySvg() {
    const svg = await this.svg;
    return this.modifySvg(svg);
  }

  get svg() {
    return fetch(this.svgPathValue).then(response => response.text());
  }

  modifySvg(svg) {
    const svgDoc = this.parseSvg(svg);
    this.changeSvgStrokeColor(svgDoc);
    this.changeSvgFillColor(svgDoc);
    return this.serializeSvg(svgDoc);
  }

  parseSvg(svg) {
    let parser = new DOMParser();
    return parser.parseFromString(svg, "image/svg+xml");
  }

  changeSvgStrokeColor(svgDoc) {
    let strokeElement = svgDoc.getElementById("stroke");
    strokeElement.setAttribute("stroke", this.stationSecondaryColorValue);
  }

  changeSvgFillColor(svgDoc) {
    if (!this.stationPrimaryColorValue) return;

    let fillElement = svgDoc.getElementById("fill");
    fillElement.setAttribute("fill", this.stationPrimaryColorValue);
  }

  serializeSvg(svgDoc) {
    let serializer = new XMLSerializer();
    return serializer.serializeToString(svgDoc.documentElement);
  }

  setSvgAsBackground(svg) {
    const url = "data:image/svg+xml," + encodeURIComponent(svg);
    this.element.style.background = `url("${url}") no-repeat center top`;
    this.element.style.backgroundSize = "cover";
  }
}
