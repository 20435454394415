import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["navbar", "player", "link"];

  openPlayer() {
    this.toggleVisibility(this.navbarTarget, this.playerTarget);
  }

  closePlayer() {
    this.toggleVisibility(this.playerTarget, this.navbarTarget);
  }

  setLinkAsActive(event) {
    const newActiveIndex = parseInt(event.currentTarget.getAttribute("data-index"));
    const currentActiveIndex = parseInt(this.linkTargets.find(link => link.classList.contains("active")).getAttribute("data-index"));

    if (newActiveIndex !== currentActiveIndex) { this.changeActiveMenuLink(newActiveIndex, currentActiveIndex) }
  }

  setSpecificLinkAsActive({ params: { linkIndex } }) {
    const currentActiveIndex = parseInt(this.linkTargets.find(link => link.classList.contains("active")).getAttribute("data-index"));
    const newActiveIndex = parseInt(linkIndex);

    if (newActiveIndex !== currentActiveIndex) { this.changeActiveMenuLink(newActiveIndex, currentActiveIndex) }
  }

  changeActiveMenuLink(newActiveIndex, currentActiveIndex) {
    this.fadeOutPreviousActiveLink();
    this.removeAnimationClasses();
    this.animateNextActiveLink(newActiveIndex, currentActiveIndex);
  }

  fadeOutPreviousActiveLink() {
    const currentActiveLink = this.linkTargets.find(link => link.classList.contains("active"));
    currentActiveLink.classList.add("fade-out");
    currentActiveLink.addEventListener("animationend", () => { currentActiveLink.classList.remove("fade-out") }, { once: true });
  }

  animateNextActiveLink(nextActiveIndex, currentActiveIndex) {
    const nextActiveLink = this.linkTargets.find(link => parseInt(link.getAttribute("data-index")) === nextActiveIndex);
    const switchDirection = nextActiveIndex > currentActiveIndex ? "switch-from-left" : "switch-from-right";
    nextActiveLink.classList.add(switchDirection, "active");
    nextActiveLink.addEventListener("animationend", () => { nextActiveLink.classList.remove(switchDirection) }, { once: true });
  }

  removeAnimationClasses() {
    this.linkTargets.forEach((link) => { link.classList.remove("active", "switch-from-left", "switch-from-right") });
  }

  toggleVisibility(elementToHide, elementToShow) {
    elementToHide.classList.add("hidden");
    elementToShow.classList.remove("hidden");
    elementToShow.classList.add("slide-up");
    elementToShow.addEventListener("animationend", () => { elementToShow.classList.remove("slide-up") }, { once: true });
  }
}
