import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "mobileNumberField"
  ]

  static values = {
    url: String
  };

  async generateLink() {
    const response = await this.getCode();
    const codeValue = response.code.toString();
    const url = `${this.urlValue}?code=${codeValue}`;
    await navigator.clipboard.writeText(url);
  }

  async getCode() {
    const response = await fetch(this.urlValue, {
      method: "POST",
      headers: { "Content-Type": "application/json", "X-CSRF-Token": this.csrfToken() },
      body: JSON.stringify({ mobile_number: this.mobileNumberFieldTarget.value })
    })

    return await response.json()
  }

  csrfToken() {
    return document.querySelector("[name='csrf-token']").content
  }
}
