export const uiElements = {
  toggleVisibility(elementsToShow, elementsToHide) {
    elementsToShow.forEach((element) => { element.classList.remove("hidden") });
    elementsToHide.forEach((element) => { element.classList.add("hidden") });
  },

  updateVolumeSliders(volume = window.mediaElement.volume) {
    window.volumeSliders.forEach(slider => {
      slider.style.backgroundSize = `${volume * 100}% 100%`;
      slider.value = window.mediaElement.muted ? 0 : volume * 100;
    });
  },

  updateVolumeButtonVisibility(volume) {
    const [show, hide] = volume > 0 ? [window.unmuteButtons, window.muteButtons] : [window.muteButtons, window.unmuteButtons];
    this.toggleVisibility(show, hide);
  },

  ensureElementsAreUpdated() {
    document.addEventListener("visibilitychange", () => {
      this.updateElementsBasedOnAudioState();
    });
  },

  updateElementsBasedOnAudioState() {
    if (window.isNativeAudioPlaying) {
      this.toggleVisibility(window.pauseButtons, window.playButtons);
    } else {
      this.toggleVisibility(window.playButtons, window.pauseButtons);
    }
  },

  updateElementsBasedOnVideoState() {
    window.video.addEventListener("play", () => { uiElements.toggleVisibility(window.pauseButtons, window.playButtons) });
    window.video.addEventListener("pause", () => { uiElements.toggleVisibility(window.playButtons, window.pauseButtons) });
  },

  hideActivateSoundButton() {
    if (!window.activateSoundButton) { return; }

    window.activateSoundButton.classList.add("animate-out");
    setTimeout(() => { window.activateSoundButton.classList.add("hidden") }, 1000);
  },

  reloadPage() {
    let metaTag = document.createElement("meta");
    metaTag.name = "turbo-visit-control";
    metaTag.content = "reload";
    document.head.appendChild(metaTag);
    window.location.reload();
  }
}
