import { VolumeControls } from "./VolumeControls";
import { uiElements } from "./uiElements";

export const WebPlayer = {
  initialize() {
    VolumeControls.initialize();
    this.showPlayButtonOnAudioPause();
    this.showPauseButtonOnAudioPlay();
  },

  play() {
    window.audio.play();
  },

  stop() {
    window.audio.pause();
  },

  resetAudio() {
    window.audio.src = window.audio.src;
  },

  showPlayButtonOnAudioPause() {
    window.audio.addEventListener("pause", () => {
      this.resetAudio();
      uiElements.toggleVisibility(window.playButtons, window.pauseButtons);
    });
  },

  showPauseButtonOnAudioPlay() {
    window.audio.addEventListener("play", () => {
      uiElements.toggleVisibility(window.pauseButtons, window.playButtons);
    });
  }
}
