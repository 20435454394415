import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["audio", "playText", "stopText"];

  static values = { previewUrl: String }

  playOrStop() {
    this.stopOtherMedia();

    if (this.audioTarget.paused) {
      this.playAudioPreview();
    } else {
      this.stopAudioPreview();
    }
  }

  playAudioPreview() {
    this.audioTarget.src = this.previewUrlValue;
    this.audioTarget.play();
    this.toggleButtonText(true);
    this.toggleButtonTextOnTrackEnd();
  }

  stopAudioPreview() {
    this.audioTarget.pause();
    this.toggleButtonText(false);
  }

  stopOtherMedia() {
    this.stopAudios();
    this.stopVideos();
  }

  stopAudios() {
    document.querySelectorAll("audio").forEach((audio) => {
      if (audio !== this.audioTarget) {
        audio.pause();
      }
    });
  }

  stopVideos() {
    document.querySelectorAll("video").forEach((video) => {
      video.pause();
    });
  }

  toggleButtonTextOnTrackEnd() {
    this.addEndedListener();
    this.addPauseListener();
  }

  addEndedListener() {
    const endedListener = () => {
      this.toggleButtonText(false);
      this.audioTarget.removeEventListener("ended", endedListener);
    };
    this.audioTarget.addEventListener("ended", endedListener);
  }

  addPauseListener() {
    const pauseListener = () => {
      this.toggleButtonText(false);
      this.audioTarget.removeEventListener("pause", pauseListener);
    };
    this.audioTarget.addEventListener("pause", pauseListener);
  }

  toggleButtonText(isPlaying) {
    if (isPlaying) {
      this.playTextTarget.classList.add("hidden");
      this.stopTextTarget.classList.remove("hidden");
    } else {
      this.stopTextTarget.classList.add("hidden");
      this.playTextTarget.classList.remove("hidden");
    }
  }
}
