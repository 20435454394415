import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "preroll",
    "closeButton",
    "closeTime"
  ];

  static values = {
    minimumExhibitionTime: Number,
    shouldCloseAutomatically: Boolean,
    audioSource: String
  };

  exhibitionTime = this.minimumExhibitionTimeValue * 1000;

  connect() {
    window.prerollAudioSource = this.audioSourceValue;

    if (this.minimumExhibitionTimeValue > 0) {
      this.keepPrerollUntilMinimumExhibitionTime();
    } else {
      this.allowCloseButtonToClosePreroll();
    }
  }

  keepPrerollUntilMinimumExhibitionTime() {
    this.handleCloseButtonExhibition();
    this.startCountdown();
    this.closeAfterMinimumExhibitionTime();
  }

  handleCloseButtonExhibition() {
    if (this.hasCloseButtonTarget) {
      this.disableCloseButton();
      setTimeout(() => { this.enableCloseButton(); }, this.exhibitionTime);
    }
  }

  disableCloseButton() {
    this.closeButtonTarget.style.cursor = "not-allowed";
    this.closeButtonTarget.style.opacity = "0.5";
  }

  enableCloseButton() {
    this.closeButtonTarget.style.cursor = "pointer";
    this.closeButtonTarget.style.opacity = "1";
    this.allowCloseButtonToClosePreroll();
  }

  startCountdown() {
    let counter = this.minimumExhibitionTimeValue;
    this.updateTimeRemaining(counter);
    this.countdownInterval = setInterval(() => { counter--; this.updateTimeRemaining(counter); }, 1000);
  }

  updateTimeRemaining(time) {
    if (!this.hasCloseTimeTarget) return;

    if (time <= 0) {
      clearInterval(this.countdownInterval);
      this.closeTimeTarget.innerText = "";
      return;
    }
    this.updateTimingText(time);
  }

  updateTimingText(time) {
    const message = this.shouldCloseAutomaticallyValue ? "closes_in" : "you_can_close_in";
    this.closeTimeTarget.innerText = I18n.t(`titles.${message}`, { time: time });
  }

  closeAfterMinimumExhibitionTime() {
    if (this.shouldCloseAutomaticallyValue) {
      setTimeout(() => { this.closePreroll(); }, this.exhibitionTime);
    }
  }

  allowCloseButtonToClosePreroll() {
    this.closeButtonTarget.addEventListener("click", () => this.closePreroll());
  }

  closePreroll() {
    this.prerollTarget.remove();
  }
}
