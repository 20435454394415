import { Controller } from "@hotwired/stimulus";
import { loadMercadoPago } from "@mercadopago/sdk-js";

export default class extends Controller {
  static values = { publicKey: String };

  static targets = ["errorMessage"];

  async connect() {
    await loadMercadoPago();
  }

  async openCheckout({ params: { adId } }) {
    let preferenceId = await this.preferenceId(adId);

    if (preferenceId) {
      this.buildCheckout(preferenceId);
    } else {
      this.showErrorMessage();
    }
  }

  buildCheckout(preferenceId) {
    let mercadoPagoSdk = new window.MercadoPago(this.publicKeyValue);
    let options = { preference: { id: preferenceId }, autoOpen: true }

    mercadoPagoSdk.checkout(options);
  }

  showErrorMessage() {
    this.errorMessageTarget.classList.remove("hidden");
  }

  async preferenceId(adId) {
    let request = await fetch(`/payments/new?ad_id=${adId}`)
    let response = await request.json()

    return response.preference_id
  }
}
