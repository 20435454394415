import { uiElements } from "./uiElements";

export const Preroll = {
  play() {
    window.audio.src = window.prerollAudioSource;
    this.playAudio();
  },

  playAudio() {
    window.audio.play().then(() => {
      uiElements.toggleVisibility(window.pauseButtons, window.playButtons);
      window.audio.addEventListener("pause", this.handleAudioPause, { once: true });
    });
  },

  handleAudioPause() {
    window.audio.src = window.audioSource;
    window.prerollAudioSource = null;
    window.activateSoundButton?.classList.remove("hidden");
    window.player.play();
  }
}
