import { AndroidPlayer } from "./AndroidPlayer";
import { IosPlayer } from "./IosPlayer";
import { VideoPlayer } from "./VideoPlayer";
import { uiElements } from "./uiElements";

export const VideoOnBackground = {
  initialize() {
    this.isPausedManually = false;
    this.ensureVideoPlaysOnBackground();
  },

  ensureVideoPlaysOnBackground() {
    if (!window.isMobile) return;

    this.handleVisibilityChange = this.handleVisibilityChange.bind(this);
    document.addEventListener("visibilitychange", this.handleVisibilityChange);
  },

  handleVisibilityChange() {
    if (this.isReloading) return;

    window.isAndroidApp || window.isIosApp ? this.ensureNativeVideoPlaysOnBackground() : this.ensureWebVideoPlaysOnBackground();
  },

  ensureNativeVideoPlaysOnBackground() {
    const play = window.isAndroidApp ? AndroidPlayer.play() : IosPlayer.play();
    this.shouldPlayNativeBackgroundVideo() ? play : this.stopBackgroundVideo();
  },

  ensureWebVideoPlaysOnBackground() {
    if (!this.isPausedManually && window.video.paused) VideoPlayer.play();
  },

  shouldPlayNativeBackgroundVideo() {
    return !window.isNativeAudioPlaying && !this.isPausedManually && !window.changingStations && !window.video.muted;
  },

  stopBackgroundVideo() {
    window.isAndroidApp ? AndroidPlayer.stop() : IosPlayer.stop();
    this.isReloading = true;
    uiElements.reloadPage();
    setTimeout(() => { this.isReloading = false; }, 1000);
  },

  removeVisibilityChangeListener() {
    document.removeEventListener("visibilitychange", this.handleVisibilityChange);
  }
}
