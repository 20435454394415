import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    appId: String,
    androidApp: Boolean,
    iosApp: Boolean,
  }

  connect() {
    this.handleAndroidRegistration();
    this.handleIosRegistration();
  }

  handleAndroidRegistration() {
    if (this.androidAppValue) {
      Native.registerForNotifications(this.appIdValue);
    }
  }

  handleIosRegistration() {
    if (this.iosAppValue) {
      webkit.messageHandlers.Native.postMessage(
        JSON.stringify({ onesignal_app_id: this.appIdValue })
      );
    }
  }
}
