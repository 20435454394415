import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = {
    categoryId: Number,
  }

  currentPage = 1;
  swiper = this.element.swiper;
  hasMorePosts = true;
  isLoading = false;

  connect() {
    this.swiper.on("progress", () => {
      const progress = this.swiper.progress;

      if (progress >= 0.7) { this.loadMorePosts() }
    });
  }

  loadMorePosts() {
    if (this.hasMorePosts && !this.isLoading) {
      this.isLoading = true;
      this.currentPage++;
      this.performRequest();
    }
  }

  performRequest() {
    console.log("Loading more posts...");

    fetch(`/posts/new?page=${this.currentPage}&category_id=${this.categoryIdValue}`)
      .then(response => response.text())
      .then(data => {
        this.createSwiperSlides(data)
        this.isLoading = false;
      });
  }

  createSwiperSlides(data) {
    if (data.trim() !== "") {
      const slides = this.parseSlides(data);
      this.appendSlides(slides);
    } else {
      this.hasMorePosts = false;
      console.log("No more posts to load");
    }
  }

  parseSlides(data) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(data, "text/html");
    const slides = Array.from(doc.querySelectorAll("swiper-slide"));
    return slides;
  }

  appendSlides(slides) {
    slides.forEach(slide => {
      this.swiper.appendSlide(slide.outerHTML);
    });
  }
}
