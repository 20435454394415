import { uiElements } from "./uiElements";

export const VolumeControls = {
  initialize() {
    this.setMediaElement();
    uiElements.updateVolumeSliders(!window.mediaElement.muted);
    uiElements.updateVolumeButtonVisibility(!window.mediaElement.muted)
  },

  setVolume(volume) {
    this.setElementVolume(volume);
    uiElements.updateVolumeButtonVisibility(volume);
    uiElements.updateVolumeSliders(volume);
  },

  muteVolume() {
    window.mediaElement.muted = true;
    uiElements.updateVolumeSliders(0);
    uiElements.toggleVisibility(window.muteButtons, window.unmuteButtons);
  },

  unmuteVolume() {
    window.mediaElement.muted = false;
    uiElements.updateVolumeSliders(window.mediaElement.volume);
    uiElements.toggleVisibility(window.unmuteButtons, window.muteButtons);
    uiElements.hideActivateSoundButton();
  },

  setElementVolume(volume) {
    window.mediaElement.volume = volume;
    window.mediaElement.muted = volume <= 0;
  },

  setMediaElement() {
    window.mediaElement = window.showVideo ? window.video : window.audio
  }
}
