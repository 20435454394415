import { Controller } from "@hotwired/stimulus";
import consumer from "../channels/consumer";

export default class extends Controller {
  static values = {
    slug: String,
    slogan: String,
    stationName: String,
    stationLogo: String,
    isIosApp: Boolean,
    isAndroidApp: Boolean
  }

  static targets = [
    "program",
    "song",
    "image",
    "programImage",
    "likeButton",
    "dislikeButton",
    "songRating"
  ]

  connect() {
    this.createSubscription()
  }

  disconnect() {
    this.removeSubscription()
  }

  createSubscription() {
    const self = this
    const channelParams = { channel: "NoarChannel", slug: this.slugValue }

    consumer.subscriptions.create(channelParams, {
      received(data) {
        console.log(`Receiving data from NoarChannel: ${self.slugValue}`)
        self.handleReceivedData(data)
      }
    })
  }

  removeSubscription() {
    consumer.subscriptions.subscriptions.forEach((subscription) => { consumer.subscriptions.remove(subscription) })
  }

  reloadSubscription() {
    this.removeSubscription()
    this.createSubscription()
  }

  handleReceivedData(data) {
    this.updateProgramAndSong(data)
    this.addMediaMetadata(data)
    this.updateIosMetadata(data)
    this.updateLikeAndDislikeButtons(data)
  }

  updateProgramAndSong(data) {
    const { program_name, speaker_name, song, artist, program_image, artwork } = data.noar;

    this.updateProgram(program_name, speaker_name, program_image, artwork)
    this.updateSong(song, artist)
  }

  updateProgram(program, speaker, program_image, artwork) {
    this.programTargets.forEach((element) => { element.textContent = this.programAndSpeaker(program, speaker) });
    this.imageTargets.forEach((element) => { element.src = this.liveContentImage(artwork, program_image) });
    if (this.hasProgramImageTarget) this.programImageTarget.src = this.programImageOrLogo(program_image)
  }

  liveContentImage(artwork, program_image) {
    return this.isEmpty(artwork) ? this.programImageOrLogo(program_image) : artwork
  }

  programImageOrLogo(program_image) {
    return this.isEmpty(program_image) ? this.stationLogoValue : program_image
  }

  updateSong(song, artist) {
    this.songTargets.forEach((element) => { element.textContent = this.songOrSlogan(song, artist) });
  }

  addMediaMetadata(data) {
    if (this.isAndroidAppValue) return;

    const { program_name, speaker_name, song, artist, program_image, artwork } = data.noar;

    navigator.mediaSession.metadata = new MediaMetadata(
      {
        title: this.songOrSlogan(song, artist),
        artist: this.programAndSpeaker(program_name, speaker_name),
        artwork: [{ src: this.liveContentImage(artwork, program_image), sizes: "512x512", type: "image/png" }]
      }
    );
  }

  updateIosMetadata(data) {
    if (this.isIosAppValue) { webkit.messageHandlers.Native.postMessage(this.iOSMetadata(data)) }
  }

  iOSMetadata(data) {
    const { program_name, speaker_name, song, artist, program_image, artwork } = data.noar;

    return JSON.stringify({
      update_now_playing_info: {
        logo_url: this.liveContentImage(artwork, program_image),
        station_name: this.songOrSlogan(song, artist),
        slogan: this.programAndSpeaker(program_name, speaker_name)
      }
    });
  }

  updateLikeAndDislikeButtons(data) {
    if (!this.hasSongRatingTarget) return;

    const shouldShowButtons = this.songAndArtistArePresent(data.noar.song, data.noar.artist);
    this.toggleButtonsVisibility(shouldShowButtons);
  }

  toggleButtonsVisibility(isVisible) {
    const action = isVisible ? "remove" : "add";
    this.likeButtonTargets.forEach((element) => { element.classList[action]("hidden") });
    this.dislikeButtonTargets.forEach((element) => { element.classList[action]("hidden") });
    this.songRatingTarget.classList[isVisible ? "remove" : "add"]("hidden");
  }

  songOrSlogan(song, artist) {
    if (this.songAndArtistArePresent(song, artist)) {
      return `${song} - ${artist}`;
    } else {
      return `${this.stationNameValue} - ${this.sloganValue}`;
    }
  }

  programAndSpeaker(program, speaker) {
    if (this.isEmpty(speaker)) {
      return program
    } else {
      return `${program} ${I18n.t("titles.with")} ${speaker}`
    }
  }

  songAndArtistArePresent(song, artist) {
    return !this.isEmpty(song) && !this.isEmpty(artist)
  }

  isEmpty(value) {
    return value == null || value.length === 0
  }
}
