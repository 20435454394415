import { Controller } from "@hotwired/stimulus";
import DOMPurify from "dompurify";

export default class extends Controller {
  connect() {
    const editorNodes = this.element.querySelectorAll(".trix-content > *");

    editorNodes.forEach(content => this.embedYoutubeVideos(content));
  }

  embedYoutubeVideos(content) {
    const isYoutubeFrame = /<iframe[^>]*src="https:\/\/(www\.)?youtube\.com\/[^>]*>.*<\/iframe>/g;

    content.innerText.match(isYoutubeFrame)?.forEach(frameText =>
      this.embedFrame(content, this.findFrameTextNode(content, frameText))
    )
  }

  findFrameTextNode(content, frameText) {
    return Array.from(content.childNodes).find(node => node.nodeType === Node.TEXT_NODE && node.textContent.includes(frameText));
  }

  embedFrame(content, iframeTextNode) {
    if (iframeTextNode) {
      content.replaceChild(this.buildIframe(iframeTextNode.textContent), iframeTextNode);
    }
  }

  buildIframe(frameText) {
    let iframe = document.createElement("iframe");

    Object.assign(iframe, {
      src: this.sanitizedYoutubeUrl(frameText),
      allow: "accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share",
      allowfullscreen: "true"
    });

    return iframe;
  }

  sanitizedYoutubeUrl(frameText) {
    let rawUrl = frameText.match(/src="(.*?)"/)[1]

    return DOMPurify.sanitize(rawUrl);
  }
}
