import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["carousel"];

  connect() {
    this.focusOnCurrentProgram();
  }

  focusOnCurrentProgram() {
    const currentProgram = document.querySelector("swiper-slide.current");

    if (currentProgram) {
      this.slideToProgram(currentProgram);
    }
  }

  slideToProgram(currentProgram) {
    const slidesCount = this.carouselTarget.swiper.slides.length;
    const slidePosition = currentProgram.dataset.index;
    const accessingFromDesktop = window.matchMedia("(min-width: 1199px)").matches;

    this.slideCarousel(slidesCount, slidePosition, accessingFromDesktop);
  }

  slideCarousel(slidesCount, slidePosition, accessingFromDesktop) {
    if (slidesCount > 3 && accessingFromDesktop) {
      this.carouselTarget.swiper.slideTo(slidePosition - 1, 0, false);
    } else {
      this.carouselTarget.swiper.slideTo(slidePosition, 0, false);
    }
  }
}
