import { Controller } from "@hotwired/stimulus";
import { VideoOnBackground } from "../utils/VideoOnBackground";

export default class extends Controller {
  static targets = ["selector", "icon", "modal"];

  toggleMobileSelector() {
    this.modalTarget.classList.remove("hidden");
    this.iconTargets.forEach(icon => { icon.classList.toggle("hidden"); });
    this.handleSlideTransition();
  }

  handleSlideTransition() {
    if (this.modalTarget.classList.contains("slide-down")) {
      this.showMobileSelector();
    } else {
      this.hideMobileSelector();
    }
  }

  showMobileSelector() {
    this.selectorTarget.classList.add("fixed");
    this.modalTarget.classList.remove("slide-down");
    this.modalTarget.classList.add("slide-up");
  }

  hideMobileSelector() {
    this.selectorTarget.classList.remove("fixed");
    this.modalTarget.classList.remove("slide-up");
    this.modalTarget.classList.add("slide-down");
  }

  toggleDesktopSelector() {
    this.selectorTarget.classList.remove("hidden");
    this.selectorTarget.classList.toggle("animate-out");

    this.iconTargets.forEach(icon => {
      icon.classList.toggle("hidden");
      icon.classList.add("animate-in");
    });
  }

  changeStation({ params: { url } }) {
    this.prepareChanging(url);
    setTimeout(() => { location.reload(); }, 800);
  }

  prepareChanging(url) {
    window.changingStations = true;
    this.allowFullPageReload();
    this.prepareAllTurboPermanentToBeReloaded();
    this.prepareNewCurrentStation(url);
    this.stopMedia();
  }

  stopMedia() {
    if (window.showVideo) {
      window.player.stop();
      VideoOnBackground.removeVisibilityChangeListener();
    } else if (window.isIosApp) {
      window.player.stop();
    }
  }

  allowFullPageReload() {
    let metaTag = document.createElement("meta");
    metaTag.name = "turbo-visit-control";
    metaTag.content = "reload";
    document.head.appendChild(metaTag);
  }

  prepareAllTurboPermanentToBeReloaded() {
    let turboPermanentElements = document.querySelectorAll("[data-turbo-permanent]");
    turboPermanentElements.forEach(element => { element.removeAttribute("data-turbo-permanent") });
  }

  prepareNewCurrentStation(url) {
    fetch(url);
  }
}
