import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };
  static targets = ["adId"];

  redirectToSelectedAd() {
    window.location = this.selectedAdUrl();
  }

  selectedAdUrl() {
    let selectedAdId = this.adIdTarget.value;
    let urlWithoutAdId = this.urlValue;

    return urlWithoutAdId.replace(":id", selectedAdId);
  }
}
