import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    setInterval(() => { this.refreshTime() }, 1000);
  }

  refreshTime() {
    const time = new Date();
    const hours = time.getHours().toString().padStart(2, "0");
    const minutes = time.getMinutes().toString().padStart(2, "0");

    this.element.textContent = `${hours}:${minutes}`;
  }
}
