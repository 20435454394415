import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "likeButton",
    "dislikeButton"
  ];

  static values = {
    url: String
  };

  like() {
    this.handleInteraction("like");
  }

  dislike() {
    this.handleInteraction("dislike");
  }

  handleInteraction(type) {
    this.sendInteraction(type);
    const isLike = type === "like";
    this.likeButtonTarget.classList.toggle("active", isLike);
    this.dislikeButtonTarget.classList.toggle("active", !isLike);
  }

  sendInteraction(actionType) {
    fetch(this.urlValue, {
      method: "POST",
      body: JSON.stringify({ action_type: actionType }),
      headers: this.requestHeaders()
    });
  }

  requestHeaders() {
    return {
      "X-CSRF-Token": this.csrfToken(),
      "Content-Type": "application/json"
    };
  }

  csrfToken() {
    return document.querySelector("meta[name=csrf-token]").content;
  }
}
