import Autocomplete from "stimulus-autocomplete";

export default class extends Autocomplete {
  enableNeighborhoodAndStreet() {
    let listener_street = document.getElementById("listener_street");
    let city_id = document.getElementById("listener_city_id");

    this.urlValue = `/autocomplete/cities/${city_id.value}/neighborhoods`;
    this.inputTarget.removeAttribute("readonly");

    if (listener_street) {
      listener_street.removeAttribute("readonly");
    }
  }
}
