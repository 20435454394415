import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  observer = null;
  heroPlayer = document.querySelector("#desktop-hero-player");
  bottomBar = document.querySelector("#bottom-bar");

  connect() {
    if (this.heroPlayer) {
      this.hideBottomPlayerWhenHeroIsDisplayed()
      this.onlyShowHeroInHome()
      document.addEventListener("turbo:render", () => { this.onlyShowHeroInHome() })
    }
  }

  hideBottomPlayerWhenHeroIsDisplayed() {
    this.observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        this.showOrHideBottomBar(entry);
      });
    });

    this.observer.observe(this.heroPlayer);
  }

  onlyShowHeroInHome() {
    const inHomePage = window.location.pathname === "/";

    if (inHomePage) {
      this.heroPlayer.classList.remove("hidden");
    } else {
      this.heroPlayer.classList.add("hidden");
    }
  }

  showOrHideBottomBar(entry) {
    const heroInTheScreen = entry.isIntersecting;

    if (heroInTheScreen) {
      this.bottomBar.classList.add("hidden");
    } else {
      this.bottomBar.classList.remove("hidden");
    }
  }
}