import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };

  incrementCategoryViewCount({ params: { categoryId } }) {
    let categoryCountUrl = this.categoryCountUrl(categoryId);

    fetch(categoryCountUrl)
  }

  categoryCountUrl(categoryId) {
    let urlWithoutCategoryId = this.urlValue

    return urlWithoutCategoryId.replace(":id", categoryId);
  }
}
