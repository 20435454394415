import { uiElements } from "./uiElements";

export const AndroidPlayer = {
  initialize() {
    if(!window.prerollAudioSource) this.play();
    uiElements.ensureElementsAreUpdated();
  },

  play() {
    Native.play(this.appParams());
    uiElements.toggleVisibility(window.pauseButtons, window.playButtons);
  },

  stop() {
    Native.stop();
    uiElements.toggleVisibility(window.playButtons, window.pauseButtons);
  },

  appParams() {
    return JSON.stringify({
      stream_url: window.showVideo ? window.videoSource : window.audioSource,
      logo_url: window.logoUrl,
      station_name: window.stationName,
      slogan: window.slogan,
    });
  }
}
