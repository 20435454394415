import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["carousel", "day"]

  connect() {
    this.focusOnCurrentDay();
  }

  changeSelectedDay(event) {
    this.dayTargets.forEach(day => day.classList.remove('selected'));
    event.currentTarget.classList.add('selected');
    this.carouselTarget.swiper.slideTo(this.dayTargets.indexOf(event.currentTarget));
  }

  focusOnCurrentDay() {
    const currentDayIndex = this.dayTargets.findIndex(day => day.classList.contains('selected'));

    if (currentDayIndex >= 5) {
      this.carouselTarget.swiper.slideTo(currentDayIndex);
    }
  }
}