import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  mobileHeroPlayer = document.querySelector("#mobile-hero-player");

  connect() {
    if (this.mobileHeroPlayer) {
      this.onlyShowHeroInHome()
      document.addEventListener("turbo:frame-render", (event) => { this.onlyShowHeroInHome(event) })
    }
  }

  onlyShowHeroInHome(event) {
    const urlPath = this.getUrlPath(event);
    const inHomePage = urlPath === "/";
    this.mobileHeroPlayer.classList[inHomePage ? "remove" : "add"]("hidden");
  }

  getUrlPath(event) {
    return event?.detail?.fetchResponse?.response?.url
      ? new URL(event.detail.fetchResponse.response.url).pathname
      : window.location.pathname;
  }
}
