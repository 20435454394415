import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  connect() {
    this.scrollToTop();
    this.clearSearchFormInputs();
  }

  scrollToTop() {
    setTimeout(() => { window.scrollTo(0, 0); }, 200);
  }

  clearSearchFormInputs() {
    const form = document.getElementById("song-search-form");
    const inputs = form.querySelectorAll("input");
    inputs.forEach(input => { input.value = ""; });
  }
}
