import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static values = { url: String };

  share() {
    if (navigator.share) {
      navigator.share({
        title: document.title,
        url: this.urlValue
      });
    }
  }
}
