import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "input",
    "results",
    "song",
    "artist",
    "previewUrl",
    "artworkUrl",
    "trackViewUrl",
    "form",
    "audioPlayer",
    "itunesLink",
    "itunesArtwork",
    "itunesSong",
    "itunesArtist"
  ];

  search() {
    const term = this.inputTarget.value;
    const url = `https://itunes.apple.com/search?term=${encodeURIComponent(term)}&media=music&limit=6`;

    this.fetchData(url).then(data => this.updateResults(data));
  }

  async fetchData(url) {
    const response = await fetch(url);
    return await response.json();
  }

  updateResults(data) {
    this.resultsTarget.innerHTML = "";
    data.results.forEach(result => {
      const resultElement = this.createResultElement(result);
      this.resultsTarget.appendChild(resultElement);
    });
  }

  createResultElement(result) {
    const resultElement = document.createElement("div");
    resultElement.classList.add("result-item");
    resultElement.innerHTML = this.resultItem(result);
    resultElement.addEventListener("click", () => this.selectResult(result));
    return resultElement;
  }

  resultItem(result) {
    return `
      <img src="${result.artworkUrl100.replace('100x100bb', '600x600bb')}" alt="${result.trackName}">
      <div>
        <span>${result.trackName}</span>
        <p>${result.artistName}</p>
      </div>
    `;
  }

  selectResult(result) {
    this.setInputValues(result);
    this.setPreviewValues(result);
    this.formTarget.classList.remove("hidden");
  }

  setInputValues(result) {
    this.songTarget.value = result.trackName;
    this.artistTarget.value = result.artistName;
    this.previewUrlTarget.value = result.previewUrl;
    this.artworkUrlTarget.value = result.artworkUrl100.replace("100x100bb", "600x600bb");
    this.trackViewUrlTarget.value = result.trackViewUrl;
  }

  setPreviewValues(result) {
    this.itunesArtworkTarget.src = result.artworkUrl100.replace("100x100bb", "600x600bb");
    this.itunesSongTarget.innerText = result.trackName;
    this.itunesArtistTarget.innerText = result.artistName;
    this.audioPlayerTarget.src = result.previewUrl;
    this.itunesLinkTarget.href = result.trackViewUrl;
  }
}
