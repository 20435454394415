import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = [
    "bannerImage",
    "bannerLink"
  ];

  static values = {
    banners: Array,
    authToken: String,
    interval: Number,
    baseExternalUrl: String
  };

  bannerPosition = 0
  shouldStopIncrementingViews = false

  connect() {
    this.swapBanners()
  }

  swapBanners() {
    this.showBanner(this.bannersValue[this.bannerPosition])
    this.prepareNextRotation()
  }

  showBanner(banner) {
    this.changeBannerImage(banner)
    this.changeBannerExternalUrl(banner)
    this.incrementBannerViews(banner)
  }

  changeBannerImage(banner) {
    this.bannerImageTarget.src = banner.image_url
  }

  changeBannerExternalUrl(banner) {
    if (banner.external_url) {
      this.bannerLinkTarget.href = `${this.baseExternalUrlValue}/${banner.id}`
    } else {
      this.bannerLinkTarget.removeAttribute("href")
    }
  }

  prepareNextRotation() {
    if (this.bannersValue.length == 1) return

    this.bannerPosition++
    this.handleBannersQueueEnding()
    this.rotatesBannerWhenIntervalEnds()
  }

  handleBannersQueueEnding() {
    if (this.bannerPosition >= this.bannersValue.length) {
      this.bannerPosition = 0
      this.shouldStopIncrementingViews = true
    }
  }

  rotatesBannerWhenIntervalEnds() {
    let rotationInterval = this.intervalValue * 1000

    setTimeout(() => { this.swapBanners(this.bannersValue) }, rotationInterval);
  }

  incrementBannerViews(banner) {
    if (this.shouldStopIncrementingViews) return

    fetch(`/banner_views/${banner.id}/`, {
      method: "PUT", headers: { "X-CSRF-Token": this.authTokenValue }
    })
  }
}
