import { Controller } from "@hotwired/stimulus";

export default class extends Controller {
  static targets = ["modal", "verifyCodeForm"];

  openModal() {
    this.modalTarget.classList.remove("hidden");
  }

  async verifyCode() {
    if (await this.isCodeValid()) {
      loginCallback(this.listener());
    } else {
      alert(I18n.t("titles.invalid_code"));
    }
  }

  async isCodeValid() {
    const request = await fetch(this.verifyCodeFormTarget.action, this.requestOptions())
    const response = await request.json()

    return response.valid === true;
  }

  requestOptions() {
    const formData = new FormData(this.verifyCodeFormTarget);
    return { method: "POST", body: formData };
  }

  listener() {
    return {
      email: this.verifyCodeFormTarget.querySelector("input[name='email']").value,
      phoneNumber: this.verifyCodeFormTarget.querySelector("input[name='phone_number']").value,
      authenticating: true
    }
  }
}
